<template>
	<div class="ybd-view">
		<ucenterHeader ></ucenterHeader>
		<div class="container">
			<ucenterLeftNav></ucenterLeftNav>
			<div class="main">
				<div class="u-rightbar">
					<div class="module-inner">
						<ul class="creat-form clearfix">
							<li v-for="item in typeList" :key="item.id">
								<a href="javascript:void(0)" @click="createForm(item.id,item.type);" class="item-inner">
									<img src="@/assets/images/ico_form.png" alt="" v-if="item.type == 2">
									<img src="@/assets/images/ico_exam.png" alt="" v-else-if="item.type == 3">
									<img src="@/assets/images/ico_ques.png" alt="" v-else>
									<dl>
										<dt>{{item.name}}</dt>
										<dd>{{item.describe}}</dd>
										<dd v-if="item.type == 3"><el-button round type="warning" size="small">立即创建</el-button></dd>
										<dd v-else-if="item.type == 2"><el-button round type="success" size="small">立即创建</el-button></dd>
										<dd v-else><el-button round type="primary" size="small">立即创建</el-button></dd>
									</dl>
								</a>
							</li>
						</ul>
					</div>
					<div class="u-module mgt flex-module">
						<div class="module-inner">
							<ul class="classify-ul">
								<li :class="{cur:listParams.fromType==''}">
									<a href="javascript:void(0);" @click="formQuery('');">全部<span v-if="allData.sumCount>99">（99+）</span><span v-else>（{{allData.sumCount}}）</span></a>
								</li>
								<li :class="{cur:listParams.fromType=='ques'}">
									<a href="javascript:void(0);" @click="formQuery('ques');">问卷调查<span v-if="allData.quesCount>99">（99+）</span><span v-else>（{{allData.quesCount}}）</span></a>
								</li>
								<li :class="{cur:listParams.fromType=='form'}">
									<a href="javascript:void(0);" @click="formQuery('form');">表单<span v-if="allData.formCount>99">（99+）</span><span v-else>（{{allData.formCount}}）</span></a>
								</li>
								<li :class="{cur:listParams.fromType=='exam'}">
									<a href="javascript:void(0);" @click="formQuery('exam');">测评<span v-if="examCount>99">（99+）</span><span v-else>（{{examCount}}）</span></a>
								</li>
								<!-- <li :class="{cur:listParams.fromType=='vote'}">
									<a href="javascript:void(0);" @click="formQuery('vote');">投票<span v-if="allData.voteCount>99">（99+）</span><span v-else>（{{allData.voteCount}}）</span></a>
								</li> -->
							</ul>
							<div class="temp-ul active">
								<div class="temp-ul-title"><div @click="showToggle(1);"><i v-if="showFlag1">&lt;</i><i v-else>&gt;</i><span>最近</span></div></div>
								<div class="temp-ulchild" v-if="showFlag1">
									<div class="c-questionnaire">
										<ul class="questionnaire-list clearfix" v-if="recently.length > 0">
											<li v-for="item in recently" :key="item._id">
												<div class="item-inner">
													<template v-if="item.status != 2">
														<div class="item-tip tip-ques" v-if="item.fromType == 'ques'">问卷</div>
														<div class="item-tip tip-form" v-else-if="item.fromType == 'form'">表单</div>
														<div class="item-tip tip-exam" v-else-if="item.fromType == 'exam'">测评</div>
														<div class="item-imgbox"><img src="/form/ucenter/img/form_default_img.jpg" alt=""></div>
														<dl class="item-content">
															<dt class="ellipsis">{{item.title}}</dt>
															<dd>{{item.createTime}}</dd>
														</dl>
														<div class="item-info clearfix">
															<div class="fl">{{item.date_count}}份数据</div>
															<div class="font-orange" v-if="item.status == 1"><i></i><span>未发布</span></div>
															<div class="font-green" v-else><i></i><span>收集中</span></div>
														</div>
													</template>
													<template v-else>
														<div class="item-tip tip-ques" v-if="item.fromType == 'ques'">问卷</div>
														<div class="item-tip tip-form" v-else-if="item.fromType == 'form'">表单</div>
														<div class="item-tip tip-exam" v-else-if="item.fromType == 'exam'">测评</div>
														<div class="item-imgbox" @click="detail(item._id,item.fromType)"><img src="/form/ucenter/img/form_default_img.jpg" alt=""></div>
														<dl class="item-content" @click="detail(item._id,item.fromType)">
															<dt class="ellipsis">{{item.title}}</dt>
															<dd>{{item.createTime}}</dd>
														</dl>
														<div class="item-info clearfix">
															<div class="fl">{{item.date_count}}份数据</div>
															<div class="font-orange" v-if="item.status == 1"><i></i><span>未发布</span></div>
															<div class="font-green" v-else><i></i><span>收集中</span></div>
														</div>
													</template>
													<div class="item-operate">
														<ul class="clearfix">
															<li><a href="javascript: void(0);" @click="editForm(item._id,item.fromType)"><i class="i-ico i-edit"></i><span>编辑</span></a></li>
															<li v-if="item.status != 2"><a href="javascript: void(0);" @click="publishForm(item._id,item.fromType)"><i class="i-ico i-publish"></i><span>发布</span></a></li>
															<li v-else><a href="javascript: void(0);" @click="publishStop(item._id)"><i class="i-ico i-publish"></i><span>暂停发布</span></a></li>
															<li><a href="javascript: void(0);" @click="dateList(item._id,item.fromType);"><i class="i-ico i-data"></i><span>数据</span></a></li>
															<li>
																<a href=""><i class="i-ico i-more"></i><span>更多</span></a>
																<dl>
																	<dd><a href="javascript: void(0);" @click="browseForm(item._id,item.fromType)">预览项目</a></dd>
																	<dd><a href="javascript: void(0);" @click="fromCopy(item._id,item.title);">复制项目</a></dd>
																	<dd><a href="javascript: void(0);" @click="fromExport(item._id);">导出项目</a></dd>
																	<dd><a href="javascript: void(0);" @click="addRecycleBin(item._id);">删除项目</a></dd>
																	<!-- <dd v-if="item.AdvState == 1"><a href="javascript: void(0);" @click="wipeAdvertising(item._id);">去广告</a></dd> -->
																</dl>
															</li>
														</ul>
													</div>
												</div>
											</li>
										</ul>
										<noData v-else text="暂时还没有表单模板哦！"></noData>
									</div>
								</div>
							</div>
							<div class="temp-ul active">
								<div class="temp-ul-title"><div @click="showToggle(2);"><i>&gt;</i><span>更早</span></div></div>
								<div class="temp-ulchild" v-if="showFlag2">
									<div class="c-questionnaire">
										<template v-if="list.length > 0">
											<ul class="questionnaire-list clearfix">
												<li v-for="item in list" :key="item._id">
													<div class="item-inner">
														<template v-if="item.status != 2">
														<div class="item-tip tip-ques" v-if="item.fromType == 'ques'">问卷</div>
														<div class="item-tip tip-form" v-else-if="item.fromType == 'form'">表单</div>
														<div class="item-tip tip-exam" v-else-if="item.fromType == 'exam'">测评</div>
														<div class="item-imgbox"><img src="/form/ucenter/img/form_default_img.jpg" alt=""></div>
														<dl class="item-content">
															<dt class="ellipsis">{{item.title}}</dt>
															<dd>{{item.createTime}}</dd>
														</dl>
														<div class="item-info clearfix">
															<div class="fl">{{item.date_count}}份数据</div>
															<div class="font-orange" v-if="item.status == 1"><i></i><span>未发布</span></div>
															<div class="font-green" v-else><i></i><span>收集中</span></div>
														</div>
													</template>
													<template v-else>
														<div class="item-tip tip-ques" v-if="item.fromType == 'ques'">问卷</div>
														<div class="item-tip tip-form" v-else-if="item.fromType == 'form'">表单</div>
														<div class="item-tip tip-exam" v-else-if="item.fromType == 'exam'">测评</div>
														<div class="item-imgbox" @click="detail(item._id,item.fromType)"><img src="/form/ucenter/img/form_default_img.jpg" alt=""></div>
														<dl class="item-content" @click="detail(item._id,item.fromType)">
															<dt class="ellipsis">{{item.title}}</dt>
															<dd>{{item.createTime}}</dd>
														</dl>
														<div class="item-info clearfix">
															<div class="fl">{{item.date_count}}份数据</div>
															<div class="font-orange" v-if="item.status == 1"><i></i><span>未发布</span></div>
															<div class="font-green" v-else><i></i><span>收集中</span></div>
														</div>
													</template>
														<div class="item-operate">
															<ul class="clearfix">
																<li><a href="javascript: void(0);" @click="editForm(item._id,item.fromType)"><i class="i-ico i-edit"></i><span>编辑</span></a></li>
																<li v-if="item.status != 2"><a href="javascript: void(0);" @click="publishForm(item._id,item.fromType)"><i class="i-ico i-publish"></i><span>发布</span></a></li>
																<li v-else><a href="javascript: void(0);" @click="publishStop(item._id)"><i class="i-ico i-publish"></i><span>暂停发布</span></a></li>
																<li><a href="javascript: void(0);" @click="dateList(item._id,item.fromType);"><i class="i-ico i-data"></i><span>数据</span></a></li>
																<li>
																	<a href=""><i class="i-ico i-more"></i><span>更多</span></a>
																	<dl>
																		<dd><a href="javascript: void(0);" @click="browseForm(item._id,item.fromType)">预览项目</a></dd>
																		<dd><a href="javascript: void(0);" @click="fromCopy(item._id,item.title);">复制项目</a></dd>
																		<dd><a href="javascript: void(0);" @click="fromExport(item._id);">导出项目</a></dd>
																		<dd><a href="javascript: void(0);" @click="addRecycleBin(item._id);">删除项目</a></dd>
																		<!-- <dd v-if="item.AdvState == 1"><a href="javascript: void(0);" @click="wipeAdvertising(item._id);">去广告</a></dd> -->
																	</dl>
																</li>
															</ul>
														</div>
													</div>
												</li>
											</ul>
											<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
										</template>
										<noData v-else text="暂时还没有表单模板哦！"></noData>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { get,post } from "/src/services/ajax_ucenter.js";
import ucenterHeader from "@/components/ucenterHeader.vue";
import ucenterLeftNav from "@/components/ucenterLeftNav.vue";
import noData from "@/components/noData";
export default {
	components: {
		ucenterHeader,
		ucenterLeftNav,
		noData,
	},
	data(){
		return {
			allData:{},
			typeList: [],
			list:[],
			recently:[],
			showFlag1:true,
			showFlag2:false,
			listParams:{
				pageNo:1,
				pageSize:20,
				fromType:'',
			},
			count: 0,
		}
	},
	created(){
		sessionStorage.setItem('formTypeId','');
		sessionStorage.setItem('formId','');
		sessionStorage.setItem('formTypeString','');
		this.getTplList();
	},
	computed: {
		examCount:function(){
			// Number(this.allData.subCount)-Number(this.allData.quesCount)-Number(this.allData.formCount)-Number(this.allData.voteCount)
			return this.allData.sumCount-this.allData.quesCount-this.allData.formCount-this.allData.voteCount
		}
	},
	watch:{
		$route(){
			this.getTplList();
		},
		count:{
			handler(){
				let count = 0;
				if(this.listParams.fromType == 'ques'){
					count = this.allData.quesCount
				}else if(this.listParams.fromType == 'form'){
					count = this.allData.formCount
				}else if(this.listParams.fromType == 'vote'){
					count = this.allData.voteCount
				}else if(this.listParams.fromType == ''){
					count = this.allData.subCount
				}
				return count;
			},
			deep:true,
			immediate:true
		}
	},
	methods: {
		// 切换列表
		formQuery(fromType){
			const _this = this;
			_this.listParams.fromType = fromType;
			_this.listParams.pageNo = 1;
			_this.getTplList()
		},
		// 创建表单
		createForm(formTypeId,type){
			const _this = this;
			let params = {
				formTypeId,
				type
			}
			if(type == 1){
				_this.$router.push({
					path:"/ques_questionTypes",
					query:params
				})
			}else if(type == 3){
				_this.$router.push({
					path:"/exam_questionTypes",
					query:params
				})
			}else{
				_this.$router.push({
					path:"/questionTypes",
					query:params
				})
			}
		},
		// 获取模板列表
		getTplList(){
			const _this = this;
			get('/formadmin/create_from.jhtml',_this.listParams).then(res => {
				if(res.code == 200){
					_this.allData = res.data;
					_this.count = _this.allData.count;
					_this.list = _this.allData.date;
					_this.typeList = _this.allData.typeList;
					_this.recently = _this.allData.recently;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		pageCurrentChange(parm){
			this.listParams.pageNo = parm;
			this.getTplList()
		},
		pageSizeChange(parm){
			this.listParams.pageSize = parm;
			this.getTplList()
		},
		// 列表展开收起
		showToggle(n){
			const _this = this;
			if(n == 1){
				_this.showFlag1 = !_this.showFlag1;
			}else{
				_this.showFlag2 = !_this.showFlag2;
			}
		},
		// 编辑表单
		editForm(formId,fromType){
			const _this = this;
			this.$confirm('修改后您收集的关于该表单的所有数据将背删除！确定继续？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/stop_publish.jhtml',{formId}).then(res => {
					if(res.code == 200){
						let url = '';
						if(fromType == 'form'){
							url = '/questionTypes';
						}else if(fromType == 'exam'){
							url = '/exam_questionTypes';
						}else{
							url = '/ques_questionTypes';
						}
						_this.$router.push({
							path:url,
							query:{
								formId
							}
						})
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
			
		},
		// 表单发布
		publishForm(id,fromType){console.log(id,fromType)
			const _this = this;
			this.$confirm('您确定要发布项目吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/form_publish.jhtml',{formId:id}).then(res => {
					if(res.code == 200){
						_this.$message.success('发布成功！');
						_this.$router.push({
							path:'/share',
							query:{
								formId:id,
								fromType
							}
						})
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
			
		},
		// 表单暂停发布
		publishStop(id){
			const _this = this;
			post('/formadmin/stop_publish.jhtml',{formId:id}).then(res => {
				if(res.code == 200){
					_this.$message.success('暂停发布成功！');
					_this.getTplList();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 表单数据
		dateList(id,fromType){
			const _this = this;
			let url = '';
			if(fromType == 'form'){
				url = '/formData';
			}else if(fromType == 'ques'){
				url = '/quesData';
			}else if(fromType == 'exam'){
				url = '/examData';
			}else if(fromType == 'vote'){
				url = '/statics';
			}
			_this.$router.push({
				path:url,
				query:{
					id
				}
			})
		},
		// 表单预览
		browseForm(id,fromType){
			const _this = this;
			let url = '';
			if(fromType == 'form'){
				url = '/preview';
			}else if(fromType == 'exam'){
				url = '/exam_preview';
			}else{
				url = '/ques_preview';
			}
			_this.$router.push({
				path:url,
				query:{
					formId:id
				}
			})
		},
		// 发布后表单详情
		detail(id,fromType){
			const _this = this;
			_this.$router.push({
				path:'/share',
				query:{
					formId:id,
					fromType
				}
			})
		},
		// 复制表单
		fromCopy(id,title){
			const _this = this;
			post('/formadmin/form_copy.jhtml',{formId:id,title}).then(res => {
				if(res.code == 200){
					_this.getTplList();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 导出
		fromExport(id){
			// const _this = this;
			window.location.href = '/formadmin/word_download.jhtml?formId='+id;
			// get('/formadmin/word_download.jhtml',{formId:id}).then(res => {
			// 	console.log(res)
			// 	if(res.code == 200){
			// 		console.log(id)
			// 	}else{
			// 		_this.$message.error(res.message);
			// 	}
			// }).catch(err => {
			// 	_this.$message.error(err);
			// })
		},
		// 去广告
		wipeAdvertising(id){
			const _this = this;
			get('/formadmin/wipe_advertising.jhtml',{formId:id}).then(res => {
				if(res.code == 200){
					_this.advVisible = true;
					_this.technical = res.data;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 使用去广告券
		useAdvCoupon(formId){
			const _this = this;
			post('/formadmin/submit_advertising.jhtml',{formId}).then(res => {
				if(res.code == 200){
					_this.$message.success("成功去除广告！");
					_this.advVisible = false;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 购买去广告券
		goToUcenterRecharge(){
			const _this = this;
			post('/formadmin/create_order.jhtml',{}).then(res => {
				if(res.code == 200){
					window.location.href = res.data.payUrl;
					_this.advVisible = false;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 删除进入回收站
		addRecycleBin(id){
			const _this = this;
			this.$confirm('您是确定删除？删除后项目将移入回收站，可进行恢复。', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/add_recycle_bin.jhtml',{id}).then(res => {
					if(res.code == 200){
						_this.getTplList();
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
		},
	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.u-rightbar {
		flex: 1;
		display: flex;
		flex-direction: column;
		.creat-form{
			margin:-10px;
			display: flex;
			flex-wrap: wrap;
			li{
				padding:10px;
				box-sizing: border-box;
				width:33.33%;
				.item-inner{
					padding:28px 20px;
					display: flex;
					border-radius: 2px;
					background: #fff;
					box-shadow: 0 0 10px 0 rgba(175,180,188,.18);
					transition: all .3s;
					img{
						width:48px;
						height: 48px;
					}
					dl{
						margin-left: 12px;
						flex: 1;
						dt{
							font-size: 16px;
							line-height: 24px;
							color:#333;
							img{float: none;
							display: inline-block;
							vertical-align: -2px;
							}
						}
						dd{
							font-size: 12px;
							line-height:20px;
							color:#999;
							margin-top:5px;
							.el-button{
								font-size: 14px;
								padding: 0 40px;
								line-height: 28px;
								margin-top: 10px;
							}
						}
					}
					&:hover{
						margin: -15px 0 15px;
					}
				}
			}
		}
		.u-module{
			border: 1px solid #eee;
			background: #fff;
			margin-top: 16px;
			flex:1;
			.module-inner{
				padding: 20px 30px;
				.classify-ul{
					overflow: hidden;
					margin-bottom: 12px;
					li{
						float: left;
						margin-right:2px;
						a{
							border-radius: 20px;
							padding:0 15px;
							line-height: 34px;
							font-size: 14px;
							color:#333;
							display: block;
							span{
								font-size: 12px;
								color:#999;
							}
							&:hover{
								background: #0cf;
								color:#fff;
								span{
									color: #fff;
								}
							}
						}
					}
					.cur a{
						background: #0cf;
						color:#fff;
						span{
							color: #fff;
						}
					}
				}
				.temp-ul{
					.temp-ul-title{
						position: relative;
						height: 30px; 
						div{
							background: #fff;
							display: inline-block;
							line-height: 30px;
							position: absolute;
							top:0;
							padding:0 15px 0 0;
							font-size: 12px;
							color:#999;
							cursor: pointer;
							i{
								transform: rotate(-270deg);
								color:#ccc;font-family: "宋体";
								display: inline-block;padding:0 8px;
							}
						}
						&:before{
							content:"";
							display: block;
							height: 15px;
							border-bottom: 1px solid #eee;
						}
					}
					.temp-ulchild{
						display: none;
					}
					.questionnaire-list {
						// min-height: 400px;
						margin: 0 -10px;
						display: flex;
						flex-wrap: wrap;
						li {
							float: left;
							width: 244px;
							box-sizing: border-box;
							padding: 12px 10px;
							.item-inner {
								border: 1px solid #e5e5e5;
								border-radius: 4px;
								position: relative;
								background: #fff;
								margin: 1px;
								.item-imgbox {
									height: 120px;
									position: relative;
									background: #f5f5f5 url(../../../assets/images/form_default_img.jpg) no-repeat center;
									img {
										max-width: 100%;
										max-height: 100%;
										position: absolute;
										top: 0;
										left: 0;
										right: 0;
										bottom: 0;
										margin: auto;
									}
								}
								.item-content {
									// height: 44px;
									padding: 10px 14px;
									dt {
										font-size: 14px;
										line-height: 24px;
										color: #333;
									}
									dd {
										font-size: 12px;
										line-height: 20px;
										color: #999;
									}
								}
								.item-info {
									padding: 7px 17px;
									font-size: 14px;
									line-height: 30px;
									display: flex;
									justify-content: space-between;
									align-items: center;
								}
								.item-operate {
									background: #f7f8fa;
									border-top: 1px solid #e5e5e5;
									height: 43px;
									display: none;
									border-bottom-left-radius: 2px;
									border-bottom-right-radius: 2px;
									ul {
										display: flex;
										justify-content: space-between;
										align-items: center;
										li {
											width: 25%;
											text-align: center;
											padding: 2px 0 5px;
											position: relative;
											span {
												display: block;
												font-size: 12px;
												color: #999;
												line-height: 16px;
											}
											.i-ico {
												width: 20px;
												height: 20px;
												display: inline-block;
												vertical-align: middle;
												background:url("../../../assets/images/icon_main.png") no-repeat;
											}
											.i-edit {
												background-position: 2px -118px;
											}
											.i-publish {
												background-position: -29px -119px;
											}
											.i-data {
												background-position: -58px -117px;
											}
											.i-putin {
												background-position: -88px -118px;
											}
											.i-more {
												background-position: -117px -118px;
											}
											dl {
												position: absolute;
												left: 15px;
												top: 43px;
												z-index: 2;
												width: 80px;
												border-radius: 2px;
												background: #fff;
												border: 1px solid #e5e5e5;
												display: none;
												dd a {
													display: block;
													line-height: 35px;
													font-size: 12px;
													color: #666;
													&:hover {
														background: #f5f6fa;
													}
												}
											}
											&:hover{
												dl{
													display: block;
												}
											}
										}
									}
								}
								&:hover {
									border: 2px solid #0cf;
									margin: 0;
									box-shadow: 0 0 16px 0 rgba(6,136,168,0.18);
									.item-info {
										display: none;
									}
									.item-operate{
										display: block;
									}
								}
								.item-tip {
									position: absolute;
									top: 0;
									left: 17px;
									z-index: 1;
									font-size: 12px;
									line-height: 20px;
									padding: 0 8px;
									color: #fff;
									border-bottom-left-radius: 2px;
									border-bottom-right-radius: 2px;
									background: #0cf;
								}

								.item-tip.tip-form {
									background: #09db9d;
								}

								.item-tip.tip-exam {
									background: #f79254;
								}

								.item-tip.tip-ques,.item-tip.tip-vote {
									background: #0cf;
								}

							}
						}
					}
				}
				.active{
					.temp-ulchild{
						display: block;
					}
					.temp-ul-title i{
						transform: rotate(-90deg);
					}
				}

			}
		}
		::v-deep .el-pagination{
			padding: 20px 10px 10px;
			font-weight: normal;
			text-align: right;
			.el-pagination__total,
			.el-pagination__sizes{
				float: left;
				.el-input{
					.el-input__inner{
						&:hover{
							border-color: var(--theme-color);
						}
					}
				}
			}
			.el-pager{
				li:not(.disabled){
					&:hover{
						color: var(--theme-color);
					}
				}
				li:not(.disabled).active{
					background-color: var(--theme-color);
					&:hover{
						color: #fff;
					}
				}
			}
			.btn-prev,
			.btn-next{
				font-size: 12px;
				padding: 0 5px;
			}
		}
	}
	
		
}
</style>